/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import Header from "./header";



const Layout = ({ children }) => {
  return (

    <Container>
      <Header />
        <main>{children}</main>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Container = styled.div`
width: 100%;
max-width: 1400px;
padding: 0px 20px;
margin-right: auto;
margin-left: auto;
box-sizing: border-box;

@media(min-width: 800px){
	padding-left: 40px;
	padding-right: 40px;
}
`;

