import React, {useState} from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Colors } from '../utils/colors';
import HamburgerMenu from 'react-hamburger-menu';

const Header = () => {
	const [open, setOpen] = useState(false);

	const[display, setDisplay] = useState(false);

	const ref = React.useRef();

	React.useEffect(() => {
		ref.current.addEventListener('transitionstart', (e) => {
			const propertyName = e.propertyName
			console.log(`Transition Started ${propertyName}`);
			//check if transform
			if (propertyName !== 'transform') return;
			if(!open) setDisplay(true);
		})
	
	},[ref]);

	const checkTransition = ({ propertyName }) => {
		if (propertyName !== 'transform') return;
		if(!open){
			console.log("transformed Close");
			//turn off the display'
			setDisplay(false)
		} 
	}

	const handleScrollTo = (sectionId) => {

		setTimeout(() => {
			scrollTo(sectionId)
		}, 300)

		setOpen(!open)
	}

	return(
		<HeaderContainer display={`${display}`}>
			<LogoContainer><TextLogo>DParlour</TextLogo></LogoContainer>

			<MobileMenuIconImg
				isOpen={open}
				menuClicked={() => ( setOpen(!open) )}
				width={40}
				height={20}
				strokeWidth={6}
				rotate={0}
				color={Colors.primary}
				borderRadius={20}
				animationDuration={0.5}
			/>
			<MobileMenuContainer 
				ref={ref}
				open={open} 
				onTransitionEnd={(e) => checkTransition(e)}
				>
				<MenuContainer display={`${display}`}>
					<MenuItemPage to='/'>Home</MenuItemPage>
					<MenuItem onClick={() => handleScrollTo('#howitworks')}>How it works</MenuItem>
					<MenuItem onClick={() => handleScrollTo('#features')}>Features</MenuItem>
					<MenuItem onClick={() => handleScrollTo('#perks')}>Perks</MenuItem>
					{/* <MenuItem onClick={() => scrollTo('#contact')}>Contact</MenuItem> */}
				</MenuContainer> 
				{/* <AuthButtonContainer>
					<CustomButton>Login</CustomButton>
					<SignUpButton background={Colors.primary} color='white'>Sign Up</SignUpButton>
				</AuthButtonContainer>  */}
			</MobileMenuContainer>
		</HeaderContainer>
	)
}



export default Header;


const HeaderContainer = styled.div`
  display: flex;
	align-items: center;
	width: 100%;
  height: 100px;
	justify-content: space-between;
	position: relative;
	overflow: ${({ display }) => display ? 'visible' : 'hidden'};
`;

const LogoContainer = styled.div`
  font-size: 25px;
  font-family: "Circular Std Bold", sans-serif;
  font-weight: bold;
	color: ${Colors.tertiary};
	@media (min-width: 1200px){
		font-size: 32px
	}
`;

const TextLogo = styled(Link)`
text-decoration: none;
color: inherit;
`;

const MenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	
	@media(min-width: 1200px){
		flex-direction: row;
		justify-content: flex-end;
		flex: 3;
		margin-right: 20px;
	}
`;

// const AuthButtonContainer = styled.div`
//   display: flex;
// 	flex-direction: column;
// 	margin-top: 20px;
// 	border: 1px solid red;
// 	align-items: center;

// 	@media(min-width: 1200px){
// 		flex-direction: row;
// 		flex: 1;
// 	}
// `;

// const SignUpButton = styled(CustomButton)`
// 	margin-top: 20px;
// 	margin-left: 0;
// `;

const MenuItem = styled.a`
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
	color: #5E5D64;
  text-align: left;
  text-decoration: none;
  padding: 2px 10px;
	opacity: 0.7;
	
	cursor: pointer;
`;

const MenuItemPage = styled(Link)`
	font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
	color: #5E5D64;
  text-align: left;
  text-decoration: none;
  padding: 2px 10px;
	opacity: 0.7;
	cursor: pointer;
`;


const MobileMenuIconImg = styled(HamburgerMenu)`
position: fixed;
align-self: center;
margin-bottom: 0;
z-index:10;
cursor: pointer;

@media(min-width: 1200px){
	display: none;
}

`;

const MobileMenuContainer = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: 200px;
	flex-direction: column;
	height: 100vh;
	padding-top: 80px;
	background-color: white;
	z-index: 2;
	transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
	transition: all 0.3s ease-in-out;

	@media(min-width: 1200px){
		position: relative;
		display: flex;
		flex-direction: row;
		height: 100px;
		transform: translateX(0);
		padding-top: 0px;
		align-items: center;
		width: 400px;
	}

`;